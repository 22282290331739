body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
  background: #efefef;
  font-size: 1rem;
  padding: 2rem;
  box-sizing: border-box;
}

a {
  text-decoration: none;
}

a:hover {
  border-bottom: 2px dashed blue;
}
